import { createStore } from 'vuex';
import headerNavigation from './modules/headerNavigation';
import topBanner from './modules/topBanner';
import aboutScooterCards from './modules/aboutScooterCards';
import coaches from './modules/coaches';
import partners from './modules/partners';
import feedbackCards from './modules/feedbackCards';
import homeQuestions from './modules/homeQuestions';
import footerNavigation from './modules/footerNavigation';

export default createStore({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		headerNavigation,
		topBanner,
		aboutScooterCards,
		coaches,
		partners,
		feedbackCards,
		homeQuestions,
		footerNavigation,
	},
});
