const footerNavigation = {
	state: {
		navigation: [
			{
				id: 1,
				title: 'ГЛАВНАЯ',
				url: '/home',
			},
			{
				id: 2,
				title: 'Самокатный спорт',
				url: '/scooter-sport',
			},
			{
				id: 3,
				title: 'Самокат-школа',
				url: '/scooter-school',
			},
			{
				id: 4,
				title: 'KSS park',
				url: '/kss-park',
			},
			{
				id: 5,
				title: 'Групповые тренировки',
				url: '/group-training',
			},
			{
				id: 6,
				title: 'Индивидуальные тренировки',
				url: '/individual-training',
			},
			{
				id: 7,
				title: 'Правила посещения тренировки',
				url: '/rules',
			},
			{
				id: 8,
				title: 'Мастер-классы',
				url: '/master-classes',
			},
			{
				id: 9,
				title: 'Обучалки',
				url: '/training',
			},
			{
				id: 10,
				title: 'Часто задаваемые вопросы',
				url: '/faq',
			},
			{
				id: 11,
				title: 'ТРЕНЕРСКИЙ СОСТАВ',
				url: '/team',
			},
			{
				id: 12,
				title: 'Мероприятия',
				url: '/events',
			},
			{
				id: 13,
				title: 'Медиа',
				url: '/media',
			},
			{
				id: 14,
				title: 'КОНТАКТЫ',
				url: '/contacts',
			},
		],

		socials: [
			{
				id: 1,
				title: 'VK',
				url: 'http://vk.com',
				svg: `
				<svg width="46" height="45"><path fill="none" d="M-1-1h48v47H-1z"/><path fill="#fff" d="M22.304 0C9.988 0 .004 10.073.004 22.5S9.988 45 22.304 45s22.3-10.073 22.3-22.5S34.62 0 22.304 0zm8.576 25.385s1.972 1.964 2.458 2.876c.014.019.02.037.025.047.198.335.244.595.147.79-.163.323-.72.483-.91.497h-3.485c-.242 0-.748-.064-1.362-.49-.471-.333-.936-.88-1.389-1.411-.676-.792-1.26-1.477-1.85-1.477a.704.704 0 0 0-.222.035c-.446.146-1.017.788-1.017 2.499 0 .534-.418.841-.713.841h-1.596c-.544 0-3.375-.192-5.884-2.862-3.07-3.27-5.835-9.827-5.858-9.888-.175-.424.185-.651.578-.651h3.52c.468 0 .622.288.729.543.125.298.585 1.482 1.34 2.813 1.224 2.17 1.974 3.051 2.576 3.051a.692.692 0 0 0 .323-.084c.785-.44.639-3.265.604-3.85 0-.11-.002-1.264-.404-1.817-.288-.4-.779-.553-1.076-.61a1.28 1.28 0 0 1 .462-.396c.54-.271 1.51-.311 2.474-.311h.537c1.045.014 1.315.082 1.693.178.767.185.783.684.716 2.393-.021.485-.042 1.033-.042 1.68 0 .14-.007.29-.007.45-.023.87-.051 1.856.558 2.262.079.05.17.077.264.077.212 0 .848 0 2.572-2.983.531-.96.993-1.958 1.382-2.986.035-.061.137-.249.258-.321a.613.613 0 0 1 .288-.068h4.137c.45 0 .76.068.818.243.102.28-.019 1.13-1.908 3.71l-.843 1.123c-1.712 2.264-1.712 2.38.107 4.097z"/></svg>`,
			},
			{
				id: 2,
				title: 'Instagram',
				url: 'http://#',
				svg: `
				<svg width="45" height="46"><path fill="none" d="M-1-1h47v48H-1z"/><circle cx="23.005" cy="23.5" r="22.5" fill="#fff"/><path fill="#252C37" d="M32.312 24.5a9.308 9.308 0 1 1-18.616 0 9.308 9.308 0 0 1 18.616 0zm-15.567 0a6.259 6.259 0 1 0 12.518 0 6.259 6.259 0 0 0-12.518 0z"/><circle cx="32.309" cy="11.751" r="2.947" fill="#252C37"/></svg>`,
			},
			{
				id: 3,
				title: 'KSS',
				url: 'http://#',
				svg: `<svg width="48" height="45"><path fill="none" d="M-1-1h50v47H-1z"/><circle cx="23.004" cy="22.5" r="22.5" fill="#fff"/><path fill="#252C37" d="m22.6 18.259.577-.63h.658l.591.636v1.61h3.55V16.8L25.366 14h-3.7l-2.633 2.799v4.586l5.396 3.75v1.54l-.59.693h-.659l-.58-.692V24.66h-3.567v3.458L21.72 31h3.572l2.668-2.88v-4.864l-5.36-3.75V18.26zM32.107 18.259l.578-.63h.658l.59.636v1.61h3.55V16.8L34.874 14h-3.7l-2.633 2.799v4.586l5.396 3.75v1.54l-.59.693h-.662l-.578-.692V24.66h-3.566v3.458L31.228 31H34.8l2.667-2.88v-4.864l-5.36-3.75V18.26z"/><path fill="#252C37" d="M15.66 21.992 19.374 14h-3.938l-2.366 5.069V14H9.504v17h3.566v-6.082L15.866 31h3.935l-4.14-9.008z"/></svg>`,
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default footerNavigation;
