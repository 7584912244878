const aboutScooterCards = {
	state: {
		cards: [
			{
				id: 1,
				title: 'современный и модный',
				text: 'Увлечение , которое помогает не только поддерживать отличную физическую форму, но и обзавестись новыми друзьями по всему миру.',
			},
			{
				id: 2,
				title: 'лёгкий и безопасный',
				text: 'Экстремальный самокат легче и безопаснее в освоении, чем многие другие виды экшн спорта, поэтому начинать тренироваться можно с 5 лет как мальчикам так и девочкам .',
			},
			{
				id: 3,
				title: 'креативный и увлекательный',
				text: 'Самокатный спорт — простор для креатива и проявления индивидуальности, так что в нем каждый для себя найдет то, что интересно .',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default aboutScooterCards;
