<template>
	<footer class="footer">
		<div class="footer__wrapper container">
			<div class="footer__topSide">
				<nav class="footer__navigation">
					<router-link class="footer__link" :to="link.url" v-for="link in navigation" :key="link.id">{{
						link.title
					}}</router-link>
				</nav>
				<div class="footer__telBlock">
					<h4>Телефон для записи и вопросов</h4>
					<a href="tel:88002505006">8 (800) 250-50-06</a>
				</div>
			</div>
			<div class="footer__bottomSide">
				<div class="footer__social">
					<a :href="social.url" v-html="social.svg" v-for="social in socials" :key="social.id"></a>
				</div>
				<h4 class="footer__copy">&copy;2023, САМОКАТ-ШКОЛА KSS SCHOOL</h4>
			</div>
			<img class="thumb footer__thumb14" src="@/assets/img/thumbs1.svg" alt="thumb" />
			<img class="thumb footer__thumb15" src="@/assets/img/thumbs2.svg" alt="thumb" />
			<img class="thumb footer__thumb16" src="@/assets/img/thumbs2.svg" alt="thumb" />
			<img class="thumb footer__thumb17" src="@/assets/img/thumbs2.svg" alt="thumb" />
		</div>
	</footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'Footer',
	computed: {
		...mapState({
			navigation: (state) => state.footerNavigation.navigation,
			socials: (state) => state.footerNavigation.socials,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
