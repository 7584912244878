<template>
	<swiper
		:navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
		:modules="modules"
		:slidesPerView="3.5"
		:centeredSlides="false"
		:spaceBetween="15"
		:grabCursor="true"
		class="mySwiper feedback__swiper"
	>
		<swiper-slide v-for="card in feedbackCards" :key="card.id">
			<article class="feedback__card">
				<div class="feedback__card-titleBlock">
					<div class="feedback__nameBlock">
						<h3 class="feedback__name">{{ card.name }}</h3>
						<svg xmlns="http://www.w3.org/2000/svg" width="117" height="23" fill="none">
							<path
								fill="#252C37"
								d="m11.597.93 2.447 7.533h7.92l-6.407 4.656 2.447 7.532-6.408-4.655L5.19 20.65l2.447-7.532L1.23 8.463h7.92L11.597.931ZM35.22.93l2.448 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.447-7.532-6.407-4.656h7.92L35.22.931ZM58.846.93l2.447 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.447-7.532-6.407-4.656h7.92L58.845.931ZM82.47.93l2.448 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.448-7.532-6.408-4.656h7.92L82.471.931ZM106.096.93l2.447 7.533h7.92l-6.407 4.656 2.447 7.532-6.407-4.655-6.408 4.655 2.447-7.532-6.407-4.656h7.92l2.448-7.532Z"
							/>
						</svg>
					</div>
					<span class="feedback__date">{{ card.date }}</span>
				</div>

				<p class="feedback__text">{{ card.text }}</p>
			</article>
		</swiper-slide>
		<div class="feedback__navigation">
			<div class="swiper-button-prev feedback__navigation-prev"></div>
			<div class="swiper-button-next feedback__navigation-next"></div>
		</div>
	</swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import { Navigation } from 'swiper/modules';
import { mapState } from 'vuex';

export default {
	components: {
		Swiper,
		SwiperSlide,
	},
	setup() {
		return {
			modules: [Navigation],
		};
	},

	computed: {
		...mapState({
			feedbackCards: (state) => state.feedbackCards.feedbackCards,
		}),
	},
};
</script>

<style lang="scss" scoped>
@import 'swiper/css/navigation';
</style>
