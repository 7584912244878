import { v4 as uuidv4 } from 'uuid';

const homeQuestions = {
	state: {
		questions: [
			{
				id: uuidv4(),
				title: 'ЧТО ВЗЯТЬ НА ПЕРВУЮ ТРЕНИРОВКУ?',
				answer: `
				<ol class="questions__question-answer-list">
					<li>
						Справка от терапевта об отсутствии ограничений <br />
						для занятия экстремальным спортом.
					</li>
					<li>
						<div>
							Расписка от родителей о том, что они и ученик ознакомлены <br />
							с правилами посещения скейт-парка:
							<div class="questions__question-answer-list-buttons">
								<a href="/rules">Правила парка</a>
								<a href="/child-receipt">Расписка для детей</a>
								<a href="/parents-receipt">Расписка для родителей</a>
							</div>
						</div>
					</li>
					<li>Копия свидетельства о рождении.</li>
					<li>
						Одежда должна быть удобна для катания: спортивные <br />
						штаны или шорты, кеды на плоской подошве.
					</li>
					<li>
						На каждую тренировку необходимо взять с собой свой трюковой самокат, <br />
						защиту (шлем, наколенники, налокотники), бутылку воды.
					</li>
				</ol>
				`,
			},
			{
				id: uuidv4(),
				title: 'ЕСТЬ ЛИ ОГРАНИЧЕНИЯ ПО СОСТОЯНИЮ ЗДОРОВЬЯ?',
				answer: '',
			},
			{
				id: uuidv4(),
				title: 'ЧТО ВХОДИТ В ЭКИПИРОВКУ?',
				answer: '',
			},
			{
				id: uuidv4(),
				title: 'С КАКОГО ВОЗРАСТА МОЖНО ЗАНИМАТЬСЯ?',
				answer: '',
			},
			{
				id: uuidv4(),
				title: 'Я СМОГУ УЧАСТВОВАТЬ В СОРЕВНОВАНИЯХ?',
				answer: '',
			},
			{
				id: uuidv4(),
				title: 'ВЫ КАТАЕТЕСЬ НА ЭЛЕКТРОСАМОКАТАХ?',
				answer: '',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default homeQuestions;
