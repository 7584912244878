import { v4 as uuidv4 } from 'uuid';

const feedbackCards = {
	state: {
		feedbackCards: [
			{
				id: uuidv4(),
				name: 'Sergey l.',
				rating: 5,
				text: 'Отличное место! Площадка сделана очень классно! Инструктора молодцы! Особенно хочу отметить Илью! Видно, что и дело свое отлично знает, и с детьми находит отличный общий язык-дети его и любят, и слушаются! В магазине огромный выбор аксессуаров, из которых можно собрать отличные самокаты! В общем однозначно рекомендую 👍🏻',
				date: '27.02.2023',
			},
			{
				id: uuidv4(),
				name: 'Рома бордо',
				rating: 5,
				text: 'Занимаемся тут уже 2,5 года. Тренера отличные, с современным подходом, почти все титулованные в своём виде спорта. Отлично находят язык с детьми (и родителями). За год научили крутейшим фишкам и трюкам… Всем советую',
				date: '27.01.2023',
			},
			{
				id: uuidv4(),
				name: 'Екатерина н',
				rating: 5,
				text: 'Были здесь в школе самокатных трюков. Пришли на пробное занятие и решили приобрести абонемент. Трюки на самокатах сейчас популярны, а также и травмоопасны. Прекрасно, что ребят здесь научат технике безопасности выполнения трюков. Родители получают и занятость детей (особенно зимой, когда на улице не покатаешься), и безопасность.',
				date: '27.01.2023',
			},
			{
				id: uuidv4(),
				name: 'Дмитрий',
				rating: 5,
				text: 'Сын в восторге от школы. Наставники молодцы, стараются уделить внимание всем ученикам независимо от уровня подготовки. В магазине большой выбор самокатов и з/ч, корректно и грамотно отвечают на все "глупые" вопросы. Отличное место оттачивать мастерство в холодное время года.',
				date: '27.01.2023',
			},
			{
				id: uuidv4(),
				name: 'Имя',
				rating: 5,
				text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatibus eius eligendi ipsum, adipisci dolor repellat quia tempore unde doloremque ut laboriosam debitis deleniti cupiditate quisquam ipsa neque officiis. Iure, reiciendis.',
				date: '27.01.2023',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default feedbackCards;
