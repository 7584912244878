<template>
	<div class="coaches__cardsList">
		<div class="coaches__card" v-for="coach in coaches" :key="coach.id">
			<img :src="coach.photo" :alt="coach.name" />
			<button class="coaches__btn">
				<svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" fill="none">
					<circle cx="21" cy="21" r="15" fill="#70C1FF" transform="rotate(-34.543 21.536 21.504)" />
					<path
						fill="#fff"
						d="M29.23 22.162a.93.93 0 0 0 0-1.314l-5.916-5.915A.93.93 0 1 0 22 16.247l5.258 5.258L22 26.763a.93.93 0 0 0 1.314 1.314l5.915-5.915Zm-14.73.272h14.072v-1.858H14.5v1.858Z"
					/>
				</svg>
			</button>
			<h4 class="coaches__name">{{ coach.name }}</h4>
			<div class="coaches__rating">
				<div class="coaches__rating-stars">
					<svg
						v-for="(star, index) in 5"
						:key="index"
						xmlns="http://www.w3.org/2000/svg"
						width="13"
						height="13"
						viewBox="0 0 13 13"
						fill="none"
					>
						<path
							d="M6.23926 0.97168L7.6986 5.46307H12.4211L8.60052 8.2389L10.0599 12.7303L6.23926 9.95446L2.41865 12.7303L3.87799 8.2389L0.0573907 5.46307H4.77992L6.23926 0.97168Z"
							fill="#252C37"
						/>
					</svg>
				</div>
				{{ coach.rating }}
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'Coaches List Component',

	computed: {
		...mapState({
			coaches: (state) => state.coaches.coaches,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
