const topBanner = {
	state: {
		topSlides: [
			{
				img: require('@/assets/img/TopBanner/banner1.webp'),
			},
			{
				img: require('@/assets/img/TopBanner/banner1.webp'),
			},
			{
				img: require('@/assets/img/TopBanner/banner1.webp'),
			},
			{
				img: require('@/assets/img/TopBanner/banner1.webp'),
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default topBanner;
