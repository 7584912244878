import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueTippy from 'vue-tippy';

import '@/assets/scss/main.scss';
import 'tippy.js/dist/tippy.css';

createApp(App).use(store).use(router).use(VueTippy).mount('#app');
