<template>
	<nav class="navigation container">
		<router-link to="/"><img src="@/assets/img/logo.png" alt="logo" /></router-link>
		<div class="navigation__list">
			<router-link class="navigation__link" v-for="link in navigation" :key="link.id" :to="link.url">{{
				link.title
			}}</router-link>
		</div>
		<router-link class="navigation__sing-up" to="/sing-up">ЗАПИСАТЬСЯ НА ТРЕНИРОВКУ</router-link>
	</nav>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'Header Navigation',
	computed: {
		...mapState({
			navigation: (state) => state.headerNavigation.navigation,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
