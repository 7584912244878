<template>
	<div class="ticker">
		<h3 class="ticker__title" v-for="i in 8" :key="i">
			ОТКРЫТ НАБОР В САМОКАТ-ШКОЛУ!
			<svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" fill="none">
				<path
					fill="#fff"
					d="M35.543 8.933a1 1 0 0 0 0-1.415l-6.364-6.363a1 1 0 1 0-1.415 1.414l5.657 5.657-5.657 5.656a1 1 0 0 0 1.415 1.415l6.364-6.364ZM.335 9.226h34.5v-2H.335v2Z"
				/>
			</svg>
		</h3>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
