<template>
	<main class="main">
		<section class="banner">
			<div class="banner__content container">
				<div class="banner__firstLine">
					<h2 class="banner__title">САМОКАТ</h2>
					<button class="banner__btn">ЗАПИСАТЬСЯ НА ТРЕНИРОВКИ</button>
				</div>
				<div class="banner__secondLine">
					<svg xmlns="http://www.w3.org/2000/svg" width="140" height="57" fill="none">
						<path
							fill="#E6E6E6"
							d="m111.648.066-9.726 9.727 11.396 11.398H0v13.755h113.318l-11.396 11.397 9.726 9.727 27.998-28.002L111.648.066Z"
						/>
					</svg>
					<h2 class="banner__title">ШКОЛА <span class="banner__title--grey">KSS SCHOOL</span></h2>
				</div>
			</div>
			<img class="thumb banner__thumb1" src="@/assets/img/thumbs1.svg" alt="thumb" />
			<img class="thumb banner__thumb2" src="@/assets/img/thumbs2.svg" alt="thumb" />
			<img class="thumb banner__thumb3" src="@/assets/img/thumbs2.svg" alt="thumb" />
			<img class="thumb banner__thumb4" src="@/assets/img/thumbs2.svg" alt="thumb" />
			<img class="thumb banner__thumb5" src="@/assets/img/thumbs2.svg" alt="thumb" />
		</section>
		<section class="topSlider">
			<TopSliderComponent />
		</section>
		<TickerComponent />
		<section class="aboutScooter">
			<div class="aboutScooter__wrapper container">
				<div class="aboutScooter__firstBlock">
					<div class="aboutScooter__titleBlock">
						<h2 class="aboutScooter__title">САМОКАТНЫЙ СПОРТ</h2>
						<h2 class="aboutScooter__title aboutScooter__title--grey">
							<svg xmlns="http://www.w3.org/2000/svg" width="52" height="39" fill="none">
								<path
									fill="#E6E6E6"
									d="m32.797.814-6.632 6.634 7.771 7.772H0v9.38h33.936l-7.771 7.772 6.632 6.633L51.89 19.91 32.797.814Z"
								/>
							</svg>
							НАЧНИ ТРЕНИРОВАТЬСЯ УЖЕ СЕЙЧАС
						</h2>
					</div>
					<button class="aboutScooter__readMore">
						ЧИТАТЬ БОЛЬШЕ
						<svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" fill="none">
							<path
								fill="#fff"
								d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
							/>
						</svg>
					</button>
				</div>
				<div class="aboutScooter__secondBlock">
					<article class="aboutScooter__card" v-for="card in cards" :key="card.id">
						<h2 class="aboutScooter__card-title">
							<span>{{ card.id }}</span> {{ card.title }}
						</h2>
						<p class="aboutScooter__card-text">{{ card.text }}</p>
					</article>
					<img class="thumb aboutScooter__thumb6" src="@/assets/img/thumbs2.svg" alt="thumb" />
					<img class="thumb aboutScooter__thumb7" src="@/assets/img/thumbs2.svg" alt="thumb" />
					<img class="thumb aboutScooter__thumb8" src="@/assets/img/thumbs2.svg" alt="thumb" />
					<img class="thumb aboutScooter__thumb9" src="@/assets/img/thumbs2.svg" alt="thumb" />
				</div>
			</div>
		</section>
		<TickerComponent />
		<section class="growUp">
			<div class="growUp__block1">
				<div class="growUp__block1-titleBlock">
					<h2 class="growUp__block1-title">МЫ РАСТИМ</h2>
					<h2 class="growUp__block1-title growUp__block1-title--blue">
						<svg xmlns="http://www.w3.org/2000/svg" width="110" height="38" fill="none">
							<path
								fill="#F1F1F1"
								d="M91.3.094 84.862 6.53l7.542 7.543H0v9.104h92.405l-7.542 7.543 6.436 6.438 18.531-18.533L91.3.094Z"
							/>
						</svg>
						ЧЕМПИОНОВ!
					</h2>
				</div>
				<button class="growUp__block1-btn">ДОСТИЖЕНИЯ УЧЕНИКОВ ШКОЛЫ</button>
			</div>
			<div class="growUp__block2">
				<span class="growUp__block2-title1">Экстремальный самокат — это риск по своей природе.</span>
				<p class="growUp__block2-text growUp__block2-text--marginBottom">
					Кто катается, тот знает, что в&nbsp;этой дисциплине каждый трюк&nbsp;&mdash; это победа. Чем сложнее, выше
					и&nbsp;страшнее, тем большая награда оказывается в&nbsp;руках райдера при приземлении.
				</p>
				<p class="growUp__block2-text">
					Мы&nbsp;учим побеждать свои страхи, упорству в&nbsp;достижении целей и&nbsp;главное&nbsp;&mdash; получению
					удовольствия от&nbsp;катания. Тренировки в&nbsp;самокат-школе развивают выносливость, координацию, умение
					владеть инвентарем и&nbsp;собой в экстремальных ситуациях.
				</p>
				<span class="growUp__block2-title2">В школе экстремального самоката мы учим рисковать профессионально.</span>
				<img class="thumb growUp__thumb10" src="@/assets/img/bigThumbs.svg" alt="thumb" />
			</div>
			<div class="growUp__block3">
				<img src="@/assets/img/growUp.webp" alt="growUp" />
			</div>
		</section>
		<TickerComponent />
		<section class="reasons">
			<div class="reasons__wrapper container">
				<div class="reasons__titleBlock">
					<h2 class="reasons__title">почему нужно выбрать самокат-школу?</h2>
					<h2 class="reasons__title reasons__title--grey">
						<svg xmlns="http://www.w3.org/2000/svg" width="260" height="39" fill="none">
							<path
								fill="#E6E6E6"
								d="m240.797.814-6.632 6.634 7.771 7.772H0v9.38h241.936l-7.771 7.772 6.632 6.633L259.89 19.91 240.797.814Z"
							/>
						</svg>
						профессионализм
					</h2>
				</div>
				<div class="reasons__cardsList">
					<div class="reasons__card reasons__card--black">
						<h3 class="reasons__card-title">квалифицированные специалисты</h3>
						<p class="reasons__card-text">
							Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию,
							так&nbsp;и&nbsp;педагогическую. Постоянно совершенствуют свои навыки,&nbsp;ищут новые способы передачи
							своего опыта подрастающему поколению и&nbsp;ко&nbsp;всем находят свой подход.
						</p>
					</div>
					<div class="reasons__card reasons__card--white">
						<h3 class="reasons__card-title reasons__card-title--blue">
							Благодаря этому, процесс обучения всегда поэтапный, наполнен яркими эмоциями и успехами, а результат
							достигается всегда раньше, чем при самостоятельном обучении.
						</h3>
					</div>
					<img src="@/assets/img/reasons.webp" alt="reasons" />
				</div>
			</div>
		</section>
		<LineComponent />
		<section class="coaches">
			<div class="coaches__wrapper container">
				<div class="coaches__titleBlock">
					<h3 class="coaches__title">Тренерский</h3>
					<h3 class="coaches__title coaches__title--grey">
						<svg xmlns="http://www.w3.org/2000/svg" width="198" height="39" fill="none">
							<path
								fill="#E6E6E6"
								d="m178.131.814-6.633 6.634 7.772 7.772H.08v9.38h179.19l-7.772 7.772 6.633 6.633 19.093-19.095L178.131.814Z"
							/>
						</svg>
						состав
					</h3>
				</div>
				<CoachesListComponent />
			</div>
		</section>
		<LineComponent />
		<section class="lessonFormats">
			<div class="lessonFormats__wrapper container">
				<div class="lessonFormats__titleBlock">
					<h3 class="lessonFormats__title">форматы занятий</h3>
					<h3 class="lessonFormats__title lessonFormats__title--grey">
						<svg xmlns="http://www.w3.org/2000/svg" width="104" height="39" fill="none">
							<path
								fill="#E6E6E6"
								d="m84.052.814-6.633 6.634 7.771 7.772H0v9.38h85.19l-7.77 7.772 6.632 6.633 19.093-19.095L84.052.814Z"
							/>
						</svg>
						в самокат-школе
					</h3>
				</div>
				<div class="lessonFormats__blueCard">
					<img
						class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--blue"
						src="@/assets/img/BlueCard/blue.webp"
						alt="blueCard bg"
					/>
					<img
						class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lightblue"
						src="@/assets/img/BlueCard/lightblue.webp"
						alt="blueCard bg"
					/>
					<img
						class="lessonFormats__blueCard-bg lessonFormats__blueCard-bg--lines"
						src="@/assets/img/BlueCard/lines.webp"
						alt="blueCard bg"
					/>
					<div class="lessonFormats__content">
						<div class="lessonFormats__group">
							<h3 class="lessonFormats__lessonTitle lessonFormats__lessonTitle--marginRight">групповые тренировки</h3>
						</div>
						<div class="lessonFormats__individual">
							<h3 class="lessonFormats__lessonTitle">
								<svg xmlns="http://www.w3.org/2000/svg" width="61" height="16" fill="none">
									<path
										fill="#fff"
										d="M60.707 8.707a1 1 0 0 0 0-1.414L54.343.929a1 1 0 1 0-1.414 1.414L58.586 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h60V7H0v2Z"
									/>
								</svg>
								индивидуальные тренировки
							</h3>
							<p class="lessonFormats__description">
								Наши тренера именитые спортсмены, которые продолжают повышать как свою соревновательную квалификацию,
								так и педагогическую. Постоянно совершенствуют свои навыки, ищут новые способы передачи своего опыта
								подрастающему поколению и ко всем находят свой подход.
							</p>
							<div class="lessonFormats__btnsList">
								<button class="lessonFormats__btn">записаться на тренировку</button>
								<a class="lessonFormats__link" href="#"
									>читать больше
									<svg xmlns="http://www.w3.org/2000/svg" width="36" height="16" fill="none">
										<path
											fill="#FEFEFE"
											d="M35.207 8.707a1 1 0 0 0 0-1.414L28.843.929a1 1 0 1 0-1.414 1.414L33.086 8l-5.657 5.657a1 1 0 0 0 1.414 1.414l6.364-6.364ZM0 9h34.5V7H0v2Z"
										/>
									</svg>
								</a>
							</div>
						</div>
					</div>
					<img class="thumb lessonFormats__thumb11" src="@/assets/img/thumbs2.svg" alt="thumb" />
					<img class="thumb lessonFormats__thumb12" src="@/assets/img/thumbs2.svg" alt="thumb" />
					<img class="thumb lessonFormats__thumb13" src="@/assets/img/thumbs2.svg" alt="thumb" />
				</div>
			</div>
		</section>
		<LineComponent />
		<img class="championBanner container" src="@/assets/img/championBanner.webp" alt="Мы растим чемпионов" />
		<LineComponent />
		<section class="partners">
			<div class="partners__wrapper container">
				<div class="partners__titleBlock">
					<h3 class="partners__title">партнёры</h3>
					<h3 class="partners__title partners__title--grey">
						<svg xmlns="http://www.w3.org/2000/svg" width="61" height="39" fill="none">
							<path
								fill="#E6E6E6"
								d="M41.051.814 34.42 7.448l7.771 7.772H.5v9.38h41.69l-7.77 7.772 6.631 6.633L60.145 19.91 41.05.814Z"
							/>
						</svg>
						самокат-школы
					</h3>
				</div>
			</div>
			<div class="partners__logosList">
				<div class="partners__logosList-wrapper container">
					<img
						v-tippy="partner.title"
						v-for="partner in partners"
						:key="partner.id"
						:src="partner.img"
						:alt="partner.title"
					/>
				</div>
			</div>
		</section>
		<LineComponent />
		<section class="feedback">
			<div class="feedback__wrapper container">
				<div class="feedback__titleBlock">
					<h3 class="feedback__title">Отзывы</h3>
					<h3 class="feedback__title feedback__title--grey">
						<svg xmlns="http://www.w3.org/2000/svg" width="57" height="39" fill="none">
							<path
								fill="#E6E6E6"
								d="m37.13.814-6.632 6.634 7.771 7.772H0v9.38h38.27l-7.772 7.772 6.632 6.633L56.224 19.91 37.13.814Z"
							/>
						</svg>
						учеников и родителей
					</h3>
				</div>
			</div>
			<FeedbackSliderComponent />
		</section>
		<LineComponent />
		<section class="questions container">
			<div class="questions__wrapper">
				<h2 class="questions__title">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
				<LineComponent style="background-color: #57606f" />
				<QuestionComponent />
			</div>
		</section>
	</main>
</template>

<script>
import CoachesListComponent from '@/components/CoachesListComponent.vue';
import FeedbackSliderComponent from '@/components/FeedbackSliderComponent.vue';
import LineComponent from '@/components/LineComponent.vue';
import QuestionComponent from '@/components/QuestionComponent.vue';
import TickerComponent from '@/components/TickerComponent.vue';
import TopSliderComponent from '@/components/TopSliderComponent.vue';
import { mapState } from 'vuex';

export default {
	name: 'Home page',
	components: {
		TopSliderComponent,
		TickerComponent,
		LineComponent,
		CoachesListComponent,
		FeedbackSliderComponent,
		QuestionComponent,
	},

	computed: {
		...mapState({
			cards: (state) => state.aboutScooterCards.cards,
			coaches: (state) => state.coaches.coaches,
			partners: (state) => state.partners.partners,
		}),
	},
};
</script>

<style lang="scss" scoped></style>
