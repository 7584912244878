<template>
	<NavigationComp />
	<HomePage />
	<FooterComponent />
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';
import NavigationComp from './components/NavigationComp.vue';
import HomePage from './pages/HomePage.vue';

export default {
	components: { NavigationComp, HomePage, FooterComponent },
};
</script>

<style lang="scss" scoped></style>
