import { v4 as uuidv4 } from 'uuid';

const coaches = {
	state: {
		coaches: [
			{
				id: uuidv4(),
				name: 'Юрий Акимов',
				rating: 12,
				photo: require('@/assets/img/Coaches/akimov.webp'),
			},
			{
				id: uuidv4(),
				name: 'Илья Тарабрин',
				rating: 52,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
			{
				id: uuidv4(),
				name: 'Константин Козлов',
				rating: 8,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
			{
				id: uuidv4(),
				name: 'Захар Михин',
				rating: 29,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
			{
				id: uuidv4(),
				name: 'Дмитрий фазолов',
				rating: 16,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
			{
				id: uuidv4(),
				name: 'Стас Басанский',
				rating: 19,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
			{
				id: uuidv4(),
				name: 'Иван Соловьев',
				rating: 12,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
			{
				id: uuidv4(),
				name: 'Артём Кравченко',
				rating: 9,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
			{
				id: uuidv4(),
				name: 'Имя Фамилия',
				rating: 0,
				photo: require('@/assets/img/Coaches/empty.webp'),
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default coaches;
