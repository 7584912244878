const headerNavigation = {
	state: {
		navigation: [
			{
				id: 1,
				title: 'ГЛАВНАЯ',
				url: '/home',
			},
			{
				id: 2,
				title: 'О НАС',
				url: '/about',
			},
			{
				id: 3,
				title: 'ОБУЧЕНИЕ',
				url: '/education',
			},
			{
				id: 4,
				title: 'ТРЕНЕРСКИЙ СОСТАВ',
				url: '/team',
			},
			{
				id: 5,
				title: 'СОБЫТИЯ',
				url: '/news',
			},
			{
				id: 6,
				title: 'КОНТАКТЫ',
				url: '/contacts',
			},
		],
	},
	getters: {},
	mutations: {},
	actions: {},
};

export default headerNavigation;
